/**
 * BugsnagDriver class for handling errors
 *
 * @class BugsnagDriver
 */
class BugsnagDriver {
  /**
   * Creates an instance of BugsnagDriver.
   * @memberof BugsnagDriver
   */
  constructor(instance) {
    this.bugsnag = instance
  }
  /**
   * Notify to bugsnag
   *
   * @param {string|Error} err String or Javascript Error object
   * @param {object} opts Bugsnag error customization options
   * @memberof BugsnagDriver
   * @see https://docs.bugsnag.com/platforms/browsers/reporting-handled-errors/#sending-errors
   */
  notify(err, opts = {}) {
    this.bugsnag.notify(err, opts)
  }
  /**
   * Notify warning to bugsnag
   *
   * @param {string|Error} err String or Javascript Error object
   * @param {object} opts Bugsnag error customization options
   * @memberof BugsnagDriver
   * @see https://docs.bugsnag.com/platforms/browsers/reporting-handled-errors/#sending-errors
   */
  info(err, opts = {}) {
    const defaultOpts = {
      severity: 'info'
    }
    Object.assign(opts, defaultOpts)
    this.bugsnag.notify(err, opts)
  }
  /**
   * Notify warning to bugsnag
   *
   * @param {string|Error} err String or Javascript Error object
   * @param {object} opts Bugsnag error customization options
   * @memberof BugsnagDriver
   * @see https://docs.bugsnag.com/platforms/browsers/reporting-handled-errors/#sending-errors
   */
  warning(err, opts = {}) {
    const defaultOpts = {
      severity: 'warning'
    }
    Object.assign(opts, defaultOpts)
    this.bugsnag.notify(err, opts)
  }
  /**
   * Notify error to bugsnag
   *
   * @param {string|Error} err String or Javascript Error object
   * @param {object} opts Bugsnag error customization options
   * @memberof BugsnagDriver
   * @see https://docs.bugsnag.com/platforms/browsers/reporting-handled-errors/#sending-errors
   */
  error(err, opts = {}) {
    const defaultOpts = {
      severity: 'error'
    }
    Object.assign(opts, defaultOpts)
    this.bugsnag.notify(err, opts)
  }
}

export default BugsnagDriver
