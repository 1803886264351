export default function({ app, $axios, redirect }) {
  $axios.onRequest(config => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers.post['Content-Type'] = 'application/json'
    config.xsrfCookieName = 'CSRF-Token'
    config.xsrfHeaderName = 'X-CSRF-Token'
  })

  if (process.env.RELEASE_STAGE !== 'production') {
    $axios.onRequestError(error => {
      const req = error.request
      console.error(
        '[Axios]',
        'Request error:',
        `[${req.config.method.toUpperCase()}] ${req.config.url}`,
        error
      )
    })
    $axios.onResponseError(error => {
      const res = error.response
      console.error(
        '[Axios]',
        'Response error:',
        `[${res.config.method.toUpperCase()}] ${res.config.url}`,
        error
      )
    })
    $axios.onResponse(res => {
      console.info(
        '[Axios]',
        `[${res.status} ${res.statusText}]`,
        `[${res.config.method.toUpperCase()}]`,
        res.config.url
      )

      if (process.browser) {
        console.log(res)
      } else {
        console.log(JSON.stringify(res.data, undefined, 2))
      }

      return res
    })
  }
}
