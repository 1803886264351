import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _411042fb = () => interopDefault(import('../pages/halytyspaivystys/index.vue' /* webpackChunkName: "pages/halytyspaivystys/index" */))
const _fcd4f9d0 = () => interopDefault(import('../pages/hinnasto.vue' /* webpackChunkName: "pages/hinnasto" */))
const _320cf08b = () => interopDefault(import('../pages/kameravalvonta/index.vue' /* webpackChunkName: "pages/kameravalvonta/index" */))
const _728b71ab = () => interopDefault(import('../pages/kotiin/index.vue' /* webpackChunkName: "pages/kotiin/index" */))
const _e3cc9a4c = () => interopDefault(import('../pages/palohalytys.vue' /* webpackChunkName: "pages/palohalytys" */))
const _5613d664 = () => interopDefault(import('../pages/tarjous.vue' /* webpackChunkName: "pages/tarjous" */))
const _67f5bd2c = () => interopDefault(import('../pages/tietoa-meista.vue' /* webpackChunkName: "pages/tietoa-meista" */))
const _54a42c7b = () => interopDefault(import('../pages/yritykseen.vue' /* webpackChunkName: "pages/yritykseen" */))
const _07e3da9e = () => interopDefault(import('../pages/halytyspaivystys/tarjous.vue' /* webpackChunkName: "pages/halytyspaivystys/tarjous" */))
const _bed4c77e = () => interopDefault(import('../pages/kameravalvonta/tarjous.vue' /* webpackChunkName: "pages/kameravalvonta/tarjous" */))
const _890f553e = () => interopDefault(import('../pages/kotiin/tarjous.vue' /* webpackChunkName: "pages/kotiin/tarjous" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior,

  routes: [{
      path: "/halytyspaivystys",
      component: _411042fb,
      name: "halytyspaivystys"
    }, {
      path: "/hinnasto",
      component: _fcd4f9d0,
      name: "hinnasto"
    }, {
      path: "/kameravalvonta",
      component: _320cf08b,
      name: "kameravalvonta"
    }, {
      path: "/kotiin",
      component: _728b71ab,
      name: "kotiin"
    }, {
      path: "/palohalytys",
      component: _e3cc9a4c,
      name: "palohalytys"
    }, {
      path: "/tarjous",
      component: _5613d664,
      name: "tarjous"
    }, {
      path: "/tietoa-meista",
      component: _67f5bd2c,
      name: "tietoa-meista"
    }, {
      path: "/yritykseen",
      component: _54a42c7b,
      name: "yritykseen"
    }, {
      path: "/halytyspaivystys/tarjous",
      component: _07e3da9e,
      name: "halytyspaivystys-tarjous"
    }, {
      path: "/kameravalvonta/tarjous",
      component: _bed4c77e,
      name: "kameravalvonta-tarjous"
    }, {
      path: "/kotiin/tarjous",
      component: _890f553e,
      name: "kotiin-tarjous"
    }, {
      path: "/",
      component: _0547af50,
      name: "index"
    }, {
      path: "/24-safety-oy",
      component: _67f5bd2c,
      name: "24-safety-oy"
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}
