<template>
  <div>
    <offer-page>
      <template v-slot:title>
        Turvaa yrityksille ja kotiin
      </template>
      <p class="is-size-4 is-size-5-mobile">
        24 Safety suunnittelee tarpeeseenne sopivat turvaratkaisut, oli kysymys
        sitten yrityksestä, taloyhtiöstä tai kodista. Asiantuntijamme
        kartoittavat parhaan mahdollisen ratkaisun kanssasi.
      </p>
    </offer-page>
  </div>
</template>

<script>
export default {
  components: {
    offerPage: () => import('~/components/offerPage')
  }
}
</script>
