<template>
  <div>
    <offer-page>
      <template v-slot:title>
        Varmista hälytyksen perillemeno
      </template>
      <p class="is-size-4 is-size-5-mobile">
        24SafetyNet hälytyksensiirtoyhteys tuo toimintavarmuutta hälytyksen
        perillemenoon. Linjavalvottu yhteys välittää viestin palohälytyksestä
        hätäkeskukseen.
      </p>
      <p>
        Palveluun voidaan liittää yhteensä 16 hälytyspistettä. Ja se toimii
        kaikissa toimintavarmaa hälytyksensiirtoa vaativissa tilanteissa.
      </p>
    </offer-page>
  </div>
</template>

<script>
export default {
  components: {
    offerPage: () => import('~/components/offerPage')
  }
}
</script>
