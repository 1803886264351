var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section contact-form",attrs:{"id":"contact"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title has-text-centered"},[_vm._v("Muuta kysyttävää?")]),_vm._v(" "),_c('p',{staticClass:"subtitle has-text-centered"},[_vm._v("Jätä yhteydenottopyyntö")]),_vm._v(" "),_c('ValidationObserver',{ref:"observer",attrs:{"name":"contact","tag":"form","method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Nimi","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Nimi")]),_vm._v(" "),_c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input",class:{ 'is-danger': errors.length },attrs:{"name":"name","type":"text","placeholder":"Nimi"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'user']}})],1)]),_vm._v(" "),(errors.length)?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Sähköposti","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Sähköposti")]),_vm._v(" "),_c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"input",class:{ 'is-danger': errors.length },attrs:{"name":"email","type":"email","placeholder":"Sähköposti"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'envelope']}})],1)]),_vm._v(" "),(errors.length)?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)])]),_vm._v(" "),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Viesti","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Viestisi")]),_vm._v(" "),_c('div',{staticClass:"control"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"textarea",class:{ 'is-danger': errors.length },attrs:{"name":"message","placeholder":"Kerro tarpeesi..."},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}})]),_vm._v(" "),(errors.length)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-pulled-right"},[_c('button',{staticClass:"button is-primary",attrs:{"disabled":invalid || _vm.data.applicationSent}},[_vm._v("\n            Lähetä\n          ")])])])]}}])}),_vm._v(" "),_vm._m(0),_vm._v(" "),(_vm.data.applicationSent)?_c('div',[_c('p',{staticClass:"title is-size-3"},[_vm._v("\n        Kiitos, pyyntösi on vastaanotettu!\n      ")])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"subtitle",staticStyle:{"line-height":"3.5rem"}},[_vm._v("\n      Voit myös soittaa numeroon\n      "),_c('a',{staticClass:"has-text-weight-bold",attrs:{"href":"tel:0333928371"}},[_vm._v("\n        03 3392 8371\n      ")])])}]

export { render, staticRenderFns }