import BugsnagDriver from './BugsnagDriver'
import ConsoleDriver from './ConsoleDriver'

/**
 * Logger class for handling errors
 * @example
 * // New error object
 * Logger.error(new Error('ErrorObject'));
 * // Passing (extended) error object
 * Logger.info(errorObject);
 * // Creating custom error
 * Logger.info({name: "Error", message: "description"});
 * // Passing data
 * Logger.warning(errorObject, {metaData: data});
 * @see {https://docs.bugsnag.com/platforms/browsers/reporting-handled-errors/|Bugsnag} For options
 * @class Logger
 */
class Logger {
  /**
   * Creates an instance of Logger.
   * @param {string} apiKey
   * @memberof Logger
   */
  constructor(instance) {
    if (instance) {
      this.driver = new BugsnagDriver(instance)
    } else {
      this.driver = new ConsoleDriver()
    }
  }
  /**
   * Notify to driver
   *
   * @param {any} args
   * @memberof this
   */
  notify(...args) {
    this.driver.notify(...args)
  }
  /**
   * Notify info to driver
   *
   * @param {any} args
   * @memberof ConsoleDriver
   */
  info(...args) {
    this.driver.info(...args)
  }
  /**
   * Notify warning to driver
   *
   * @param {any} args
   * @memberof ConsoleDriver
   */
  warning(...args) {
    this.driver.warning(...args)
  }
  /**
   * Notify error to driver
   *
   * @param {any} args
   * @memberof ConsoleDriver
   */
  error(...args) {
    this.driver.error(...args)
  }
  /**
   * Set some value of a driver based on object path
   *
   * @param {string} path Object
   * @param {any}    value
   * @memberof Logger
   */
  set(path, value) {
    if (typeof path === 'string') {
      path = path.split('.')
    }
    if (path.length > 1) {
      const e = path.shift()
      this.assign(
        (this.driver[e] =
          Object.prototype.toString.call(this.driver[e]) === '[object Object]'
            ? this.driver[e]
            : {}),
        path,
        value
      )
    } else {
      this.driver[path[0]] = value
    }
  }
}

export default Logger
