<template>
  <div class="section">
    <div class="container">
      <div class="content app">
        <div class="error">
          <div class="title is-3">
            Tapahtui odottamaton virhe, mikäli virhe toistuu kehoitamme olemaan
            yhteydessä asiakaspalveluumme
          </div>
          <div class="is-size-4">{{ message }}</div>
          <p v-if="statusCode === 404" class="description">
            <nuxt-link
              to="/"
              type="button"
              class="button is-primary is-rounded"
              style="margin-top: 2em;"
            >
              Palaa etusivulle
            </nuxt-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  head () {
    return {
      title: `24 Safety | ${this.message}`
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    message() {
      let error = this.error.message
      if (error === 'This page could not be found')
        error = 'Emme valitettavasti löytäneet hakemaanne sivua.'
      return error
    }
  }
}
</script>
