<template>
  <section class="section">
    <div class="container">
      <div class="content">
        <offers />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    offers: () => import('~/components/offers')
  }
}
</script>
