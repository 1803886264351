import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'
import Logger from '~/utils/Logger/index'

// Install bugsnag when bugsnag API key is set and bugsnag is enabled
let bugsnagClient = null
if (process.env.BUGSNAG_ENABLED && process.env.BUGSNAG_JS_API_KEY) {
  bugsnagClient = bugsnag({
    apiKey: process.env.BUGSNAG_JS_API_KEY,
    releaseStage: process.env.RELEASE_STAGE || 'development',
    appVersion: process.env.APP_VERSION
  })
  bugsnagClient.use(bugsnagVue, Vue)
}

export default ({ app }, inject) => {
  // Inject logger into vue app and context
  inject('logger', new Logger(bugsnagClient))
}
