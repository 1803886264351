<template>
  <div>
    <main-hero />
    <pullet-points />
    <block :img="require('~/assets/shield_house.svg?external')">
      <h1 class="h1">Turvakartoitus</h1>
      <p>
        Meidän kauttamme saat juuri sinulle sopivat turvaratkaisut, oli kysymys
        sitten yrityksestä, taloyhtiöstä tai kodista. Asiantuntijamme
        kartoittavat parhaan mahdollisen ratkaisun yrityksesi tai kotisi
        tarpeisiin. Jätä meille yhteystietosi ja olemme yhteydessä sinuun.
      </p>
      <nuxt-link to="/tarjous" class="button is-primary">
        Pyydä kartoitus
      </nuxt-link>
    </block>
    <block :img="require('~/assets/shield_camera.svg?external')" right>
      <h2 class="h1">Kameravalvonta</h2>
      <p>
        Kameravalvonnan merkitys kiinteistöjen turvallisuuden varmistamisessa
        kasvaa.
      </p>
      <p>
        Kameravalvonnalla suojaat tehokkaasti kiinteistöä ja sen ympäristöä
        murtovarkailta sekä ilkivallalta ja helpotat tapahtumien
        jälkiselvittelyssä.
      </p>
      <p>
        24 Safety tarjoaa kameravalvonnan palveluna kiinteään kuukausihintaan.
        Näin vastuu järjestelmän toiminnasta ja palvelusta on meillä. Sopimus
        pitää sisällään laiteinvestoinnin, varaosatoimitukset, korjaukset ja
        huollon.
      </p>
      <nuxt-link to="/kameravalvonta/tarjous" class="button is-primary">
        Kysy lisää
      </nuxt-link>
    </block>
    <block :img="require('~/assets/shield_alarm.svg?external')">
      <h2 class="h1">Hälytyksen siirto</h2>
      <p>
        24SafetyNet hälytyksensiirtoyhteys tuo toimintavarmuutta hälytyksen
        perillemenoon. Linjavalvottu yhteys välittää viestin palohälytyksestä
        viranomaisten hätäkeskukseen (HÄKE).
      </p>
      <nuxt-link to="/halytyspaivystys/tarjous" class="button is-primary">
        Pyydä tarjous
      </nuxt-link>
    </block>
    <contact style="background-color: #eceff1;" />
  </div>
</template>

<script>
export default {
  head() {
    return {
      title: '24 Safety | Täyden palvelun turvatalo',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            '24 Safety Oy:n vahvuutena on monipuolinen osaaminen ja yli 12-vuotinen kokemus teknisten turvallisuusjärjestelmien ja -palveluiden toimittamisesta sekä ammattitaitoinen ja palveluvalmis henkilöstö.' }
      ]
    }
  },
  components: {
    mainHero: () => import('~/components/index/mainHero'),
    pulletPoints: () => import('~/components/index/pulletPoints'),
    block: () => import('~/components/index/block'),
    contact: () => import('~/components/index/contact')
  }
}
</script>
