<template>
  <div class="pricing-plan">
    <div class="plan-header">{{ name }}</div>
    <div class="plan-price">
      <span class="plan-price-amount">
        <span class="plan-price-currency">€</span>{{ price.toFixed(2) }}
      </span>/kk
    </div>
    <div class="plan-items">
      <template v-for="(item, index) in items">
        <div :key="index" class="plan-item">
          <!-- Show title on mobile -->
          <p class="has-text-weight-bold">
            <!-- If title is array parse it as new lines-->
            <span v-if="Array.isArray(titles[index])" class="is-hidden-desktop">
              <span
                v-for="(titleLine, indexTitle) in titles[index]"
                :key="indexTitle"
              >
                <template v-if="titleLine === ''">
                  &nbsp;
                </template>
                <span v-else>{{ titleLine }}</span>
              </span>
            </span>
            <span v-else class="is-hidden-desktop">{{ titles[index] }}</span>
          </p>
          <!-- If item is array parse it as new lines-->
          <template v-if="Array.isArray(item)">
            <p v-for="(itemLine, indexLine) in item" :key="indexLine">
              <template v-if="itemLine === ''">
                &nbsp;
              </template>
              <span v-else>{{ itemLine }}</span>
            </p>
          </template>
          <span v-else>{{ item }}</span>
        </div>
      </template>
    </div>
    <div class="plan-footer">
      <a
        :href="`mailto:myynti@24safety.fi?subject=Tarjouspyyntö:+${name}`"
        target="_blank"
        class="button is-fullwidth is-primary"
      >
        Pyydä tarjous
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    titles: {
      type: Array,
      required: true
    }
  }
}
</script>
