<template>
  <div class="section offer-page">
    <div class="container">
      <div class="content">
        <h1 class="offer-page-title title is-1 has-text-centered">
          <slot name="title"></slot>
        </h1>
        <div class="columns is-variable is-8">
          <div class="column offer-page-form">
            <p class="title is-size-3">Kyllä, olen kiinnostunut</p>
            <p class="subtitle">
              Jätä meille yhteydenottopyyntö, niin otamme sinuun yhteyttä
            </p>
            <ValidationObserver
              ref="observer"
              v-slot="{ invalid }"
              name="offer"
              tag="form"
              method="POST"
              netlify
              @submit.prevent="submit()"
            >
              <input type="hidden" name="form-name" value="offer" />
              <div class="field">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nimi"
                  rules="required|min:2"
                >
                  <label class="label">Nimi</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.name"
                      name="name"
                      class="input"
                      :class="{ 'is-danger': errors.length }"
                      type="text"
                      placeholder="Nimi"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'user']" />
                    </span>
                  </div>
                  <p v-if="errors.length" class="help is-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div class="field">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Postinumero"
                  rules="required|min:2"
                >
                  <label class="label">Postinumero</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.postal"
                      name="postal"
                      class="input"
                      :class="{ 'is-danger': errors.length }"
                      type="number"
                      placeholder="Postinumero"
                      onkeydown="return event.keyCode !== 69"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'map']" />
                    </span>
                  </div>
                  <p v-if="errors.length" class="help is-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div class="field">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Puhelinnumero"
                  rules="required|min:2"
                >
                  <label class="label">Puhelinnumero</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.phone"
                      name="phone"
                      class="input"
                      :class="{ 'is-danger': errors.length }"
                      type="number"
                      placeholder="Puhelinnumero"
                      onkeydown="return event.keyCode !== 69"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'phone']" />
                    </span>
                  </div>
                  <p v-if="errors.length" class="help is-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div class="field">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Sähköposti"
                  rules="email"
                >
                  <label class="label">Sähköposti</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.email"
                      name="email"
                      class="input"
                      :class="{ 'is-danger': errors.length }"
                      type="email"
                      placeholder="Sähköposti"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon :icon="['fas', 'envelope']" />
                    </span>
                  </div>
                  <p v-if="errors.length" class="help is-danger">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div class="field">
                <div class="control is-pulled-right">
                  <button
                    class="button is-primary"
                    :disabled="invalid || data.applicationSent"
                  >
                    Lähetä
                  </button>
                </div>
              </div>
              <div v-if="data.applicationSent">
                <p class="title is-size-3">
                  Kiitos, pyyntösi on vastaanotettu!
                </p>
              </div>
            </ValidationObserver>
          </div>
          <div class="column">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      data: {
        submitLoading: false,
        applicationError: null,
        applicationSent: false
      },
      form: {
        name: '',
        postal: '',
        phone: '',
        email: ''
      }
    }
  },
  methods: {
    async submit() {
      this.data.submitLoading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.data.applicationError =
          'Hakemusta ei voida lähettää ennen kuin puuttuvat kentät on korjattu.'
        try {
          this.$el
            .querySelector(`[name="${this.$validator.errors.items[0].field}"]`)
            .scrollIntoView()
        } catch (error) {
          this.$logger.error(error, {
            metaData: {
              'Validator errors': this.$validator.errors
            }
          })
        }
        this.data.submitLoading = false
        return
      }
      let result = {}
      try {
        result = await this.$axios.$post('/.netlify/functions/offer', this.form)
      } catch (error) {
        this.$logger.error(error)
      }
      if (result.success) {
        this.data.applicationSent = true
      }
      this.data.applicationError = null
      this.data.submitLoading = false
    }
  }
}
</script>

<style lang="scss">
.offer-page {
  background-image: url('https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  color: white;

  &-title {
    padding-bottom: 0.5em;
  }

  .content h1,
  .content h2 {
    color: white;
  }

  &:after {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(150, 150, 150, 1) 100%
    );
    content: '';
    height: 100%;
    opacity: 0.25;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .container {
    z-index: 2;
  }

  &-form {
    background: white;
    color: $text;
    margin-right: 3rem;
    padding: 2rem;

    @include mobile {
      margin-left: 3rem;
      padding-bottom: 5rem;
    }
  }
}
</style>
