import { ValidationProvider, extend, localize } from 'vee-validate'
import {
  // eslint-disable-next-line camelcase
  email,
  min,
  required
} from 'vee-validate/dist/rules'
import Vue from 'vue'

/**
 * Add default rules
 */
extend('email', email)
extend('min', min)
extend('required', required)

/**
 * Add Finnish localization
 */
localize('fi', {
  code: 'fi',
  messages: {
    _default: field => `${field} ei ole validi.`,
    min: (field, { length }) =>
      `${field} tulee olla vähintään ${length} merkkiä.`,
    required: field => `${field} on pakollinen kenttä.`,
    email: field => `${field} tulee olla kelvollinen sähköpostiosoite`
  }
})

/**
 * Insert validator into Vue
 */
Vue.component('ValidationProvider', ValidationProvider)
