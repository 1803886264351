<template>
  <section class="section block" :class="{ 'is-right': right }">
    <div class="container">
      <div class="columns">
        <div v-if="!right" class="column is-4 block-image is-hidden-mobile">
          <img :src="img" />
        </div>
        <div class="column">
          <div class="content">
            <slot />
          </div>
        </div>
        <div v-if="right" class="column is-4 block-image is-hidden-mobile">
          <img :src="img" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true
    },
    right: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.block {
  &.is-right {
    background-color: #eceff1;
  }
  &-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 13rem;
    }
  }
}
</style>
