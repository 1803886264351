var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section offer-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"offer-page-title title is-1 has-text-centered"},[_vm._t("title")],2),_vm._v(" "),_c('div',{staticClass:"columns is-variable is-8"},[_c('div',{staticClass:"column offer-page-form"},[_c('p',{staticClass:"title is-size-3"},[_vm._v("Kyllä, olen kiinnostunut")]),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v("\n            Jätä meille yhteydenottopyyntö, niin otamme sinuun yhteyttä\n          ")]),_vm._v(" "),_c('ValidationObserver',{ref:"observer",attrs:{"name":"offer","tag":"form","method":"POST","netlify":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input',{attrs:{"type":"hidden","name":"form-name","value":"offer"}}),_vm._v(" "),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Nimi","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Nimi")]),_vm._v(" "),_c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input",class:{ 'is-danger': errors.length },attrs:{"name":"name","type":"text","placeholder":"Nimi"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'user']}})],1)]),_vm._v(" "),(errors.length)?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Postinumero","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Postinumero")]),_vm._v(" "),_c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.postal),expression:"form.postal"}],staticClass:"input",class:{ 'is-danger': errors.length },attrs:{"name":"postal","type":"number","placeholder":"Postinumero","onkeydown":"return event.keyCode !== 69"},domProps:{"value":(_vm.form.postal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "postal", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'map']}})],1)]),_vm._v(" "),(errors.length)?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Puhelinnumero","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Puhelinnumero")]),_vm._v(" "),_c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"input",class:{ 'is-danger': errors.length },attrs:{"name":"phone","type":"number","placeholder":"Puhelinnumero","onkeydown":"return event.keyCode !== 69"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'phone']}})],1)]),_vm._v(" "),(errors.length)?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Sähköposti","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Sähköposti")]),_vm._v(" "),_c('div',{staticClass:"control has-icons-left has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"input",class:{ 'is-danger': errors.length },attrs:{"name":"email","type":"email","placeholder":"Sähköposti"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'envelope']}})],1)]),_vm._v(" "),(errors.length)?_c('p',{staticClass:"help is-danger"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-pulled-right"},[_c('button',{staticClass:"button is-primary",attrs:{"disabled":invalid || _vm.data.applicationSent}},[_vm._v("\n                  Lähetä\n                ")])])]),_vm._v(" "),(_vm.data.applicationSent)?_c('div',[_c('p',{staticClass:"title is-size-3"},[_vm._v("\n                Kiitos, pyyntösi on vastaanotettu!\n              ")])]):_vm._e()]}}])})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_vm._t("default")],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }