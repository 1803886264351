<template>
  <section id="contact" class="section contact-form">
    <div class="container">
      <h2 class="title has-text-centered">Muuta kysyttävää?</h2>
      <p class="subtitle has-text-centered">Jätä yhteydenottopyyntö</p>
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        name="contact"
        tag="form"
        method="POST"
        @submit.prevent="submit()"
      >
        <div class="columns">
          <div class="column">
            <div class="field">
              <ValidationProvider
                v-slot="{ errors }"
                name="Nimi"
                rules="required|min:2"
              >
                <label class="label">Nimi</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    v-model="form.name"
                    name="name"
                    class="input"
                    :class="{ 'is-danger': errors.length }"
                    type="text"
                    placeholder="Nimi"
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'user']" />
                  </span>
                </div>
                <p v-if="errors.length" class="help is-danger">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <ValidationProvider
                v-slot="{ errors }"
                name="Sähköposti"
                rules="email"
              >
                <label class="label">Sähköposti</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    v-model="form.email"
                    name="email"
                    class="input"
                    :class="{ 'is-danger': errors.length }"
                    type="email"
                    placeholder="Sähköposti"
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon :icon="['fas', 'envelope']" />
                  </span>
                </div>
                <p v-if="errors.length" class="help is-danger">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="field">
          <ValidationProvider
            v-slot="{ errors }"
            name="Viesti"
            rules="required|min:5"
          >
            <label class="label">Viestisi</label>
            <div class="control">
              <textarea
                v-model="form.message"
                name="message"
                class="textarea"
                :class="{ 'is-danger': errors.length }"
                placeholder="Kerro tarpeesi..."
              ></textarea>
            </div>
            <p v-if="errors.length" class="help is-danger">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="field">
          <div class="control is-pulled-right">
            <button
              class="button is-primary"
              :disabled="invalid || data.applicationSent"
            >
              Lähetä
            </button>
          </div>
        </div>
      </ValidationObserver>
      <p class="subtitle" style="line-height: 3.5rem;">
        Voit myös soittaa numeroon
        <a href="tel:0333928371" class="has-text-weight-bold">
          03 3392 8371
        </a>
      </p>
      <div v-if="data.applicationSent">
        <p class="title is-size-3">
          Kiitos, pyyntösi on vastaanotettu!
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      data: {
        submitLoading: false,
        applicationError: null,
        applicationSent: false
      },
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    async submit() {
      this.data.submitLoading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.data.applicationError =
          'Hakemusta ei voida lähettää ennen kuin puuttuvat kentät on korjattu.'
        try {
          this.$el
            .querySelector(`[name="${this.$validator.errors.items[0].field}"]`)
            .scrollIntoView()
        } catch (error) {
          this.$logger.error(error, {
            metaData: {
              'Validator errors': this.$validator.errors
            }
          })
        }
        this.data.submitLoading = false
        return
      }
      let result = {}
      try {
        result = await this.$axios.$post(
          '/.netlify/functions/contact',
          this.form
        )
      } catch (error) {
        this.$logger.error(error)
      }
      if (result.success) {
        this.data.applicationSent = true
      }
      this.data.applicationError = null
      this.data.submitLoading = false
    }
  }
}
</script>

<style lang="scss">
.contact-form {
  .contact-form-field {
    display: none;
  }
}
</style>
