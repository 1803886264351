<template>
  <div>
    <offer-page>
      <template v-slot:title>
        Turvaa kotisi murroilta, tulipaloita ja vesivahingoilta
      </template>
      <p class="is-size-4 is-size-5-mobile">
        Kotivartija -turvapalvelu suojaa kotisi, kun olet itse poissa. Se valvoo
        myös öisin, kun sinä nukut ja kertoo päivisin, kun lapset tulevat
        koulusta kotiin. Järjestelmä räätälöidään sinun kotiisi sopivaksi
        yhdessä asiantuntijamme kanssa.
      </p>
      <p>
        Kotivartija turvapalvelun hinta on 19,90 €/kk sisältäen 24/7
        hälytyskeskus valvonnan. Vartijakäynneistä veloitetaan erikseen
        50€/kerta.
      </p>
      <img
        src="~/assets/img/kotivartia.jpg"
        alt="Kotivaria aloituspaketti"
        width="500"
        style="width: 500px;"
      />
    </offer-page>
  </div>
</template>

<script>
export default {
  components: {
    offerPage: () => import('~/components/offerPage')
  }
}
</script>
