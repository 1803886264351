/**
 * Plugin for handling font awesome 5 in nuxt project
 * @author Alan Aasmaa <alan.aasmaa@eone.fi>
 */
import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faEuroSign,
  faMap,
  faPhone,
  faCircleNotch,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons'
// import {} from '@fortawesome/free-brands-svg-icons'

library.add(
  faEnvelope,
  faEuroSign,
  faMap,
  faPhone,
  faCircleNotch,
  faTrash,
  faUser
)

dom.watch()

Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)

export default library
