<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <logo-svg class="navbar-logo" />
        </a>
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': showNav }"
          aria-label="menu"
          aria-expanded="false"
          @click="showNav = !showNav"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        class="navbar-menu"
        :class="{ 'is-active': showNav }"
        @click="showNav = false"
      >
        <div class="navbar-end">
          <nuxt-link class="navbar-item" to="/">
            Etusivu
          </nuxt-link>
          <nuxt-link class="navbar-item" to="/halytyspaivystys">
            Hälytyspäivystys
          </nuxt-link>
          <nuxt-link class="navbar-item" to="/yritykseen">
            Yritykselle
          </nuxt-link>
          <nuxt-link class="navbar-item" to="/kotiin">
            Kotiin
          </nuxt-link>
          <nuxt-link class="navbar-item" to="/kameravalvonta">
            Kameravalvonta
          </nuxt-link>
          <nuxt-link class="navbar-item" to="/palohalytys">
            Palohälytys
          </nuxt-link>
          <nuxt-link class="navbar-item" to="/tietoa-meista">
            Tietoa meistä
          </nuxt-link>
          <span class="navbar-item navbar__key-flag">
            <avainlippu-svg />
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  components: {
    avainlippuSvg: () => import('~/assets/avainlippu.svg'),
    logoSvg: () => import('~/assets/logo.svg')
  },
  data() {
    return {
      showNav: false
    }
  }
}
</script>

<style lang="scss">
.navbar {
  border-bottom: 2px solid $primary;
  font-family: Oswald, sans-serif;
  text-transform: uppercase;

  .navbar-end {
    position:relative;
  }

  .navbar-link.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  a.navbar-item.is-active,
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  .navbar-link.is-active:not(:focus):not(:hover),
  a.navbar-item.is-active:not(:focus):not(:hover) {
    background-color: lighten($secondary, 5%);
  }

  &-brand {
    background-color: $secondary;
    @include desktop {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  &-logo {
    height: 100%;
    max-height: 2.5rem;
    width: 11rem;
  }

  &__key-flag {
    background: white;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    @include touch {
      position: absolute;
      top: -0.76rem;
      right: -0.5rem;
      border-radius: 0 0 0 15px;
    }
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .navbar-burger:hover {
    span {
      color: $primary;
    }
  }

  @include touch {
    .navbar-menu.is-active {
      background-color: lighten($secondary, 10%);
      .navbar-item {
        color: $white;
        &.is-active {
          color: $primary;
        }
      }
    }
  }
}
</style>
