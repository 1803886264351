<template>
  <div>
    <offer-page>
      <template v-slot:title>
        Turvaa kiinteistösi helposti
      </template>
      <p class="is-size-4 is-size-5-mobile">
        Kameravalvonnalla suojaat tehokkaasti kiinteistöä ja sen ympäristöä
        murtovarkailta sekä ilkivallalta.
      </p>
      <p>
        24 Safety tarjoaa kattavan kameravalvonnan palveluna kiinteään
        kuukausihintaan. Suunnitellaan yhdessä kohteeseesi paras
        valvontaratkaisu.
      </p>
    </offer-page>
  </div>
</template>

<script>
export default {
  components: {
    offerPage: () => import('~/components/offerPage')
  }
}
</script>
