<template>
  <footer class="footer has-background-rlgray">
    <div class="container">
      <div class="columns">
        <div class="column">
          <logo-lion-svg class="footer-logo" />
          <br />
          <p class="footer-link-title">Osoite</p>
          <p class="footer-link">Kalevantie 7 C (7 krs )</p>
          <p class="footer-link">33100 Tampere</p>
        </div>
        <div class="column">
          <p class="footer-link-title">Asiakaspalvelu</p>
          <p class="footer-link">
            <a href="mailto:asiakaspalvelu@24safety.fi">
              <font-awesome-icon
                :icon="['fas', 'envelope']"
                style="color: white"
              />
              asiakaspalvelu@24safety.fi
            </a>
          </p>
          <p class="footer-link">
            <a href="tel:0333928370">
              <font-awesome-icon
                :icon="['fas', 'phone']"
                style="color: white"
              />
              03 3392 8370
            </a>
          </p>
          <p class="footer-link">(ark. 8-18)</p>
        </div>
        <div class="column">
          <p class="footer-link-title">Myynti</p>
          <p class="footer-link">
            <a href="mailto:myynti@24safety.fi">
              <font-awesome-icon
                :icon="['fas', 'envelope']"
                style="color: white"
              />
              myynti@24safety.fi
            </a>
          </p>
          <p class="footer-link">
            <a href="tel:0333928371">
              <font-awesome-icon
                :icon="['fas', 'phone']"
                style="color: white"
              />
              03 3392 8371
            </a>
          </p>
        </div>
        <div class="column">
          <p class="footer-link-title">Valvomo</p>
          <p class="footer-link">
            <a href="tel:0333928380">
              <font-awesome-icon
                :icon="['fas', 'phone']"
                style="color: white"
              />
              03 3392 8380
            </a>
          </p>
          <p class="footer-link">(24h päivystys)</p>
        </div>
        <div class="column is-narrow">
          <img
            src="~/assets/img/luotettava.jpg"
            style="max-width: 8rem; display: block;"
          />
          <img
            src="~/assets/img/suome-vahvimmat-24safety.png"
            style="margin-top: 1em; max-width: 8rem; display: block"
          />
        </div>
      </div>
    </div>
    <p class="footer-copyright has-text-centered">
      © 24 Safety Oy {{ new Date().getFullYear() }}
    </p>
  </footer>
</template>

<script>
export default {
  components: {
    logoLionSvg: () => import('~/assets/logo_lion.svg'),
  },
}
</script>

<style lang="scss">
.footer {
  color: $white;
  background-color: $secondary;
  border-top: 5px solid $primary;

  &-logo {
    max-width: 9rem;
  }

  &-copyright {
    color: $primary;
    font-weight: bold;
  }

  &-link-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: $primary;
    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  &-link {
    margin-top: 0.5rem;
    color: $white;
    display: block;
    a {
      color: $white;
      &:hover {
        color: $primary;
      }
    }
    p:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
