<template>
  <div>
    <top-bar />
    <navbar />
    <nuxt />
    <footer-c />
  </div>
</template>

<script>
export default {
  components: {
    footerC: () => import('~/components/theme/footer'),
    navbar: () => import('~/components/theme/navbar'),
    topBar: () => import('~/components/theme/topBar')
  }
}
</script>
